import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import tableIcons from '../elements/TableIcons';
import DateFormatted from '../elements/DateFormatted';
import {setAlert} from '../../actions/alert';
import {getUsers, deleteUser} from '../../actions/users';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    borderRadius: '0px',
    boxShadow: 'none',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const Dashboard = ({
  users: {users, loadingUser},
  getUsers,
  deleteUser,
  setAlert,
}) => {
  const history = useHistory();
  const styles = useStyles();
  const [DataToDelete, setDataToDelete] = useState({});
  const [OpenDialog, setOpenDialog] = useState(false);
  let items = [];
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (!loadingUser && users.length > 0) {
    users.forEach((item, index) => {
      items.push(item);
    });
  }

  const clickNewUser = () => {
    history.push('/users/add');
  };
  const onClickDelete = (data) => {
    setOpenDialog(true);
    setDataToDelete(data);
  };
  const onClickEdit = (data) => {
    history.push(`/users/edit/${data.link}`);
  };
  const onClickOpen = (data) => {
    history.push(`/results/${data.link}`);
  };
  const onDialogClose = () => {
    setDataToDelete({});
    setOpenDialog(false);
  };
  const onDialogConfirm = () => {
    deleteUser(DataToDelete);
    setOpenDialog(false);
    setDataToDelete({});
  };
  return (
    <Container>
      <MaterialTable
        options={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 15, 20],
          actionsColumnIndex: -1,
        }}
        icons={tableIcons}
        columns={[
          {title: 'Nama', field: 'name', headerStyle: {fontWeight: 'bold'}},
          {title: 'Email', field: 'email', headerStyle: {fontWeight: 'bold'}},
        ]}
        data={items}
        detailPanel={(rowData) => {
          return (
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom>User Detail</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <Paper className={styles.paper}>Link test:</Paper>
                  </Grid>
                  <Grid item xs={10}>
                    <Paper className={styles.paper}>
                      <CopyToClipboard
                        text={`${process.env.REACT_APP_FRONTEND_URL}/candidates/${rowData.link}`}
                        onCopy={() =>
                          setAlert(
                            'Data disalin di papan tempel.',
                            'info',
                            2000
                          )
                        }
                      >
                        <span className={styles.pointer}>{rowData.link}</span>
                      </CopyToClipboard>
                    </Paper>
                  </Grid>
                  <Grid item xs={2}>
                    <Paper className={styles.paper}>Password:</Paper>
                  </Grid>
                  <Grid item xs={10}>
                    <Paper className={styles.paper}>
                      <CopyToClipboard
                        text={`${rowData.password}`}
                        onCopy={() =>
                          setAlert(
                            'Data disalin di papan tempel.',
                            'info',
                            2000
                          )
                        }
                      >
                        <span
                          className={styles.pointer}
                        >{`${rowData.password}`}</span>
                      </CopyToClipboard>
                    </Paper>
                  </Grid>
                  <Grid item xs={2}>
                    <Paper className={styles.paper}>Umur:</Paper>
                  </Grid>
                  <Grid item xs={10}>
                    <Paper className={styles.paper}>{rowData.age}</Paper>
                  </Grid>
                  <Grid item xs={2}>
                    <Paper className={styles.paper}>Jenis kelamin:</Paper>
                  </Grid>
                  <Grid item xs={10}>
                    <Paper className={styles.paper}>{rowData.sex}</Paper>
                  </Grid>
                  <Grid item xs={2}>
                    <Paper className={styles.paper}>Tanggal dibuat:</Paper>
                  </Grid>
                  <Grid item xs={10}>
                    <Paper className={styles.paper}>
                      <DateFormatted date={rowData.dateCreated}></DateFormatted>
                    </Paper>
                  </Grid>
                  <Grid item xs={2}>
                    <Paper className={styles.paper}>Tanggal test dibuka:</Paper>
                  </Grid>
                  <Grid item xs={10}>
                    <Paper className={styles.paper}>
                      {rowData.openDate ? (
                        <DateFormatted date={rowData.openDate}></DateFormatted>
                      ) : (
                        ''
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={2}>
                    <Paper className={styles.paper}>
                      Tanggal test selesai:
                    </Paper>
                  </Grid>
                  <Grid item xs={10}>
                    <Paper className={styles.paper}>
                      {rowData.savedDate ? (
                        <DateFormatted date={rowData.savedDate}></DateFormatted>
                      ) : (
                        ''
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={styles.paper}>
                      <CopyToClipboard
                        text={`${process.env.REACT_APP_FRONTEND_URL}/candidates/${rowData.link}`}
                        onCopy={() =>
                          setAlert(
                            'Data disalin di papan tempel.',
                            'info',
                            2000
                          )
                        }
                      >
                        <Button className={styles.pointer} color='secondary'>
                          Salin Tautan Login
                        </Button>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={`${rowData.password}`}
                        onCopy={(text, result) => {
                          setAlert(
                            'Data disalin di papan tempel.',
                            'info',
                            2000
                          );
                        }}
                      >
                        <Button className={styles.pointer} color='secondary'>
                          Salin Password
                        </Button>
                      </CopyToClipboard>
                      <CopyToClipboard
                        text={`Berikut adalah link dan password untuk melakukan test DISC.\nLink: ${process.env.REACT_APP_FRONTEND_URL}/candidates/${rowData.link}\nPassword: ${rowData.password}`}
                        onCopy={() =>
                          setAlert(
                            'Data disalin di papan tempel.',
                            'info',
                            2000
                          )
                        }
                      >
                        <Button className={styles.pointer} color='secondary'>
                          Salin teks untuk dibagikan
                        </Button>
                      </CopyToClipboard>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        }}
        actions={[
          {
            icon: tableIcons.Add,
            tooltip: 'Tambah Kandidat',
            isFreeAction: true,
            onClick: (event) => clickNewUser(),
          },
          (rowData) => ({
            icon: tableIcons.Delete,
            tooltip: 'Hapus Kandidat',
            onClick: (event, rowData) => onClickDelete(rowData),
          }),
          (rowData) => ({
            icon: tableIcons.Edit,
            tooltip: 'Update Kandidat',
            onClick: (event, rowData) => onClickEdit(rowData),
          }),
          (rowData) => ({
            icon: tableIcons.FolderOpenIcon,
            tooltip: 'Lihat Hasil Test',
            onClick: (event, rowData) => onClickOpen(rowData),
            disabled:
              rowData.savedDate === undefined ||
              rowData.savedDate === 'undefined',
          }),
        ]}
        title='Kandidat'
      ></MaterialTable>
      <Dialog
        open={OpenDialog}
        onClose={onDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Hapus kandidat?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Anda yakin ingin menghapus kandidat ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color='secondary'>
            Batalkan
          </Button>
          <Button onClick={onDialogConfirm} color='secondary'>
            Ya hapus
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

Dashboard.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, {getUsers, deleteUser, setAlert})(
  Dashboard
);
