import {GET_DOWNLOAD} from '../actions/types';

const initialState = {
  download: {},
  loadingDownload: true,
  error: {},
};

export default function download(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_DOWNLOAD:
      return {
        ...state,
        download: payload,
        loadingDownload: false,
      };
    default:
      return state;
  }
}
