import {GET_CANDIDATE, LOGOUT_CANDIDATE} from '../actions/types';

const initialState = {
  candidate: {},
  candidates: [],
  loadingCandidate: true,
  error: {},
};

export default function candidates(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_CANDIDATE:
      localStorage.setItem('candidate', payload.link);
      return {
        ...state,
        candidate: payload,
        candidates: [],
        loadingCandidate: false,
      };
    case LOGOUT_CANDIDATE:
      localStorage.removeItem('candidate');
      return {
        ...state,
        candidate: {},
        candidates: [],
        loadingCandidate: false,
      };
    default:
      return state;
  }
}
