import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';
import {Container} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import {editUser, getUserById} from '../../../actions/users';

const UsersEdit = ({
  users: {user, loadingUser},
  match,
  editUser,
  getUserById,
}) => {
  const history = useHistory();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    age: '',
    sex: '',
    link: '',
  });

  const id = match.params.id;
  useEffect(() => {
    if (!formData.link) {
      getUserById(id);
      setFormData({
        link: loadingUser || !user.link ? '' : user.link,
        name: loadingUser || !user.name ? '' : user.name,
        email: loadingUser || !user.email ? '' : user.email,
        age: loadingUser || !user.age ? '' : user.age,
        sex: loadingUser || !user.sex ? '' : user.sex,
      });
    }
  }, [getUserById, id, user]);
  const onFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    editUser(formData);
    history.push('/dashboard');
  };

  return (
    <Container>
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='h6' component='h1'>
            Update Kandidat
          </Typography>
        </CardContent>
      </Card>
      <Card variant='outlined'>
        <CardContent>
          <ValidatorForm
            noValidate
            onSubmit={(e) => onFormSubmit(e)}
            onError={(errors) => console.log(errors)}
          >
            <TextValidator
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Nama'
              name='name'
              value={formData.name}
              autoFocus
              onChange={(e) => onFormChange(e)}
              validators={['required', 'trim']}
              errorMessages={['the field is required']}
            />
            <TextValidator
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email'
              name='email'
              value={formData.email}
              autoFocus
              onChange={(e) => onFormChange(e)}
              validators={['required', 'isEmail', 'trim']}
              errorMessages={[
                'the field is required',
                'the content should be email',
              ]}
            />
            <TextValidator
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='age'
              label='Umur'
              name='age'
              value={formData.age}
              autoFocus
              onChange={(e) => onFormChange(e)}
              validators={['required', 'isNumber', 'trim']}
              errorMessages={[
                'the field is required',
                'the content should be number',
              ]}
            />
            <SelectValidator
              name='sex'
              id='sex'
              variant='outlined'
              fullWidth
              onChange={(e) => onFormChange(e)}
              value={formData.sex}
              SelectProps={{
                native: true,
              }}
              validators={['required']}
              errorMessages={['please select one option']}
            >
              <option value=''>Jenis Kelamin</option>
              <option value='Laki Laki'>Laki Laki</option>
              <option value='Perempuan'>Perempuan</option>
            </SelectValidator>
            <Button variant='contained' color='primary' type='submit'>
              Save
            </Button>
          </ValidatorForm>
        </CardContent>
      </Card>
    </Container>
  );
};

UsersEdit.propTypes = {
  editUser: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, {editUser, getUserById})(UsersEdit);
