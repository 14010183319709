import {setAlert} from './alert';
import {GET_USERS, GET_USER, ADD_USER, GET_CANDIDATE} from './types';
import DayJS from 'react-dayjs';
import axios from 'axios';

export const addUser = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}/users`,
      payload
    );
    dispatch({
      type: ADD_USER,
      payload: res.data.Item,
    });
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.error(error);
  }
};

export const deleteUser = (payload) => async (dispatch) => {
  try {
    const resDelete = await axios.delete(
      `${process.env.REACT_APP_URL_BACKEND}/users/${payload.link}`
    );
    const resGets = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/users`
    );
    const data = resGets.data.items;
    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.error(error);
  }
};

export const editUser = (payload) => async (dispatch) => {
  try {
    const resEdit = await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}/users/${payload.link}`,
      payload
    );
    const resGets = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/users`
    );
    const data = resGets.data.items;
    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.log(error);
  }
};

export const getUserById = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/users/${payload}`
    );
    dispatch({
      type: GET_USER,
      payload: res.data.data[0],
    });
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.error(error);
  }
};

export const getUsers = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/users`);
    const data = res.data.items;
    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.error(error);
  }
};

export const candidateLogin = (payload) => async (dispatch) => {
  try {
    dispatch(setAlert('Mencari data kandidat...', 'info'));
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}/candidates/${payload.id}`,
      payload
    );
    const data = res.data;
    if (data.isLoggedIn) {
      if (data.isTookTest) {
        dispatch(
          setAlert(
            `Anda sudah mengambil test pada ${data.data.savedDate}. Anda tidak dapat mengambil test lagi.`,
            'warning'
          )
        );
      } else if (!data.isLinkValid) {
        dispatch(
          setAlert(
            `Link Anda sudah tidak valid, silakan meminta link baru untuk mengambil test DISC.`,
            'warning'
          )
        );
      } else {
        const editPayload = {
          openDate: Date.now(),
        };
        const resEdit = await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}/users/${payload.id}`,
          editPayload
        );
        dispatch({
          type: GET_CANDIDATE,
          payload: data.data,
        });
      }
    } else {
      dispatch(setAlert('Token/Password tidak tepat', 'error'));
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.error(error);
  }
};

export const loginCheck = () => async (dispatch) => {
  dispatch(setAlert('Mengecek status login...', 'info'));
  const token = localStorage.getItem('candidate');
  if (token) {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/users/${token}`
    );
    dispatch({
      type: GET_CANDIDATE,
      payload: res.data.data[0],
    });
  } else {
    dispatch(
      setAlert('Silakan masukan password yang sudah di berikan.', 'info')
    );
  }
};
