import {createMuiTheme} from '@material-ui/core/styles';
import '@fontsource/coda';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e8eaf6',
      light: '#ffffff',
      dark: '#b6b8c3',
    },
    secondary: {
      main: '#9fa8da',
      light: '#d1d9ff',
      dark: '#6f79a8',
    },
  },
  typography: {
    fontFamily: 'Coda, monospace',
  },
});

export default theme;
