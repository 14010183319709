import axios from 'axios';
import {setAlert} from './alert';
import {LOGIN_ADMIN} from './types';

export const checkAdmin = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}/admin`,
      payload
    );
    const {isAdmin} = res.data;
    if (isAdmin) {
      dispatch({
        type: LOGIN_ADMIN,
        payload: payload,
      });
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.log(error);
  }
};
