import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {AppBar, Toolbar, Typography, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
}));

const Menu = ({admin}) => {
  const classes = useStyles();
  const location = window.location;
  const isCandidate = location.pathname.includes('candidates');
  const localToken = localStorage.getItem('adminTko');
  return isCandidate || (!admin && !localToken) ? (
    ''
  ) : (
    <Fragment>
      <Link
        href='/dashboard'
        // variant='button'
        color='inherit'
        className={classes.link}
      >
        Dashboard
      </Link>
      <Link
        href='/questions'
        // variant='button'
        color='inherit'
        className={classes.link}
      >
        Questions List
      </Link>
      <Link
        href={process.env.REACT_APP_URL_PANEL}
        // variant='button'
        color='inherit'
        className={classes.link}
      >
        Go To Panel
      </Link>
    </Fragment>
  );
};

export const Navbar = ({admins: {admin, loadingAdmin}}) => {
  const classes = useStyles();
  return (
    <AppBar position='static'>
      <Toolbar>
        <Typography variant='h6' className={classes.title}>
          <Link underline='none' color='inherit' href='/'>
            {process.env.REACT_APP_TITLE}
          </Link>
        </Typography>
        <nav>
          <Menu admin={admin}></Menu>
        </nav>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  admins: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admins: state.admins,
});
export default connect(mapStateToProps, {})(Navbar);
