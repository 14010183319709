import React from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Card, CardContent, Typography} from '@material-ui/core';

import {checkAdmin} from '../../actions/admins';

const NoDashboard = ({admins: {admin, loadingAdmin}, checkAdmin}) => {
  const history = useHistory();

  if (window.location.href.includes('?tko')) {
    let token = window.location.href.split('?tko=');
    token = token[1];
    checkAdmin({token: token});
  }
  if (!loadingAdmin && admin) {
    history.push('/dashboard');
  }

  return (
    <Card>
      <CardContent>
        <Typography>
          Untuk mengikuti DISC Test, silakan login melalui link dan
          token/password yang sudah dikirimkan.
        </Typography>
      </CardContent>
    </Card>
  );
};

NoDashboard.propTypes = {
  admins: PropTypes.object.isRequired,
  checkAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  admins: state.admins,
});

export default connect(mapStateToProps, {checkAdmin})(NoDashboard);
