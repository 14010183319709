import axios from 'axios';
import {setAlert} from './alert';
import {GET_RESULTS, LOGOUT_CANDIDATE} from './types';

export const getResultsByUser = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/results/${payload}`
    );
    dispatch({
      type: GET_RESULTS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.log(error);
  }
};

export const postResult = (payload) => async (dispatch) => {
  try {
    dispatch(setAlert('Menyimpan jawaban...', 'info'));
    const {candidate, questions, result} = payload;
    const payloadToSend = {
      questions: questions,
      result: result,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}/results/${candidate.link}`,
      payloadToSend
    );
    const editPayload = {
      savedDate: Date.now(),
    };
    const resEdit = await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}/users/${candidate.link}`,
      editPayload
    );
    dispatch(
      setAlert(
        'Jawaban telah tersimpan, halaman test akan tertutup. Terima kasih.',
        'success'
      )
    );
    dispatch({
      type: LOGOUT_CANDIDATE,
    });
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.log(error);
  }
};
