export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';

export const GET_CANDIDATE = 'GET_CANDIDATE';
export const LOGOUT_CANDIDATE = 'LOGOUT_CANDIDATE';

export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_DOWNLOAD = 'GET_DOWNLOAD';

export const GET_RESULTS = 'GET_RESULTS';
export const ADD_RESULT = 'ADD_RESULTS';

export const LOGIN_ADMIN = 'LOGIN_ADMIN';
