import {combineReducers} from 'redux';

import alert from './alert';
import admins from './admins';
import candidates from './candidates';
import download from './download';
import questions from './questions';
import results from './results';
import users from './users';
export default combineReducers({
  admins,
  alert,
  candidates,
  download,
  questions,
  results,
  users,
});
