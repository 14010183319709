import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {Container} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import PrivateRoute from './components/routing/PrivateRoute.js';
import Navbar from './components/layout/Navbar';
import Alert from './components/layout/Alert';

import NoDashboard from './components/pages/NoDashboard';
import Dashboard from './components/pages/Dashboard';
import UsersAdd from './components/pages/users/UsersAdd';
import UsersEdit from './components/pages/users/UsersEdit';
import UsersResult from './components/pages/users/UsersResult';
import Questions from './components/pages/Questions';

import CandidateLogin from './components/pages/candidates/CandidateDashboard';

import store from './store';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  pageContent: {
    padding: theme.spacing(6, 0),
  },
}));

const App = () => {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <Router>
        <div className={classes.root}>
          <Navbar></Navbar>
          <Alert></Alert>
          <Container
            maxWidth='md'
            component='main'
            className={classes.pageContent}
          >
            <Switch>
              <Route exact path='/' component={NoDashboard}></Route>
              <PrivateRoute
                exact
                path='/dashboard'
                component={Dashboard}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path='/users/add'
                component={UsersAdd}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path='/users/edit/:id'
                component={UsersEdit}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path='/results/:id'
                component={UsersResult}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path='/questions'
                component={Questions}
              ></PrivateRoute>
              <Route
                exact
                path='/candidates/:id'
                component={CandidateLogin}
              ></Route>
            </Switch>
          </Container>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
