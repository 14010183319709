import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function AlertBox(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Alert = ({alerts}) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return alerts !== null && alerts.length > 0 ? (
    alerts.map((alert) => (
      <Snackbar
        key={alert.id}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <AlertBox onClose={handleClose} severity={`${alert.alertType}`}>
          {`${alert.msg}`}
        </AlertBox>
      </Snackbar>
    ))
  ) : (
    <Fragment />
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
