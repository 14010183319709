import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {green, red} from '@material-ui/core/colors';
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Radio,
  CardActions,
} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';

import array from '../../../utils/array';
import {postResult} from '../../../actions/results';
import {candidateLogin, loginCheck} from '../../../actions/users';
import {getQuestions} from '../../../actions/questions';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2, 0),
  },
  textP: {
    color: green[400],
  },
  textK: {
    color: red[400],
  },
}));

const PRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

const KRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

const CandidateLogin = ({
  candidates: {candidate, loadingCandidate},
  questions: {questions, loadingQuestion},
  match,
  candidateLogin,
  getQuestions,
  postResult,
  loginCheck,
}) => {
  const classes = useStyles();
  const id = match.params.id;
  let questionChunks;
  const [formData, setFormData] = useState({password: '', id: id});

  useEffect(() => {
    loginCheck();
  }, [loginCheck]);
  useEffect(() => {
    getQuestions();
  }, [candidate, getQuestions]);
  if (questions) {
    questionChunks = array.sliceIntoChunks(questions, 12);
  }
  const onFormSubmit = (e) => {
    e.preventDefault();
    candidateLogin(formData);
  };
  const onFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const [selectedValue, setSelectedValue] = useState([]);

  const handleChange = (event) => {
    let key = event.target.name;
    let keyToCheck = key.includes('P') ? 'K' : 'P';
    let indexToCheck = key.replace('P', '').replace('K', '');
    keyToCheck = keyToCheck.concat(indexToCheck);
    if (selectedValue[keyToCheck] === event.target.value) {
      console.log('sama ini');
    } else {
      setSelectedValue({
        ...selectedValue,
        [key]: event.target.value,
      });
    }
  };

  const onClickSubmitTest = (e) => {
    e.preventDefault();
    postResult({
      result: selectedValue,
      candidate: candidate,
      questions: questions,
    });
  };

  return candidate &&
    Object.keys(candidate).length === 0 &&
    candidate.constructor === Object ? (
    <Container>
      <Card variant='outlined'>
        <CardHeader
          title='Login'
          subheader='Silakan isikan password yang sudah diberikan'
        ></CardHeader>
        <CardContent>
          <ValidatorForm
            noValidate
            onSubmit={(e) => onFormSubmit(e)}
            onError={(errors) => console.log(errors)}
          >
            <TextValidator
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='password'
              label='Password'
              name='password'
              value={formData.password}
              autoFocus
              onChange={(e) => onFormChange(e)}
              validators={['required', 'trim']}
              errorMessages={['the field is required']}
            />
            <Button variant='contained' color='primary' type='submit'>
              Login
            </Button>
          </ValidatorForm>
        </CardContent>
      </Card>
    </Container>
  ) : (
    <Container>
      <Card variant='outlined' className={classes.card}>
        <CardHeader
          title={`${candidate.name}`}
          subheader={candidate.email}
        ></CardHeader>
        <CardContent>
          <Typography variant='body1'>Umur: {candidate.age}</Typography>
          <Typography variant='body1'>
            Jenis Kelamin: {candidate.sex}
          </Typography>
        </CardContent>
      </Card>
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='h6'>Instruksi</Typography>
          <Typography variant='body1' gutterBottom={true}>
            Setiap nomor di bawah ini memuat 4 (empat) kalimat yang merupakan
            representasi dari <strong>Gambaran Diri</strong>.
          </Typography>
          <Typography variant='body1'>Tugas anda adalah:</Typography>
          <Typography variant='body1'>
            1. Pilih pada kolom berwarna{' '}
            <strong className={classes.textP}>hijau</strong> di samping kalimat
            yang <strong>PALING menggambarkan</strong> diri anda
          </Typography>
          <Typography variant='body1' gutterBottom={true}>
            2. Pilih pada kolom huruf{' '}
            <strong className={classes.textK}>merah</strong> di samping kalimat
            yang <strong>PALING TIDAK menggambarkan</strong> diri anda
          </Typography>
          <Typography variant='body1' gutterBottom={true}>
            3. Jika dari empat pernyataan tersebut tidak ada yang menggambarkan
            diri anda, silakan pilih pernyataan yang <strong>paling</strong>{' '}
            mendekati gambaran diri anda
          </Typography>
          <Typography>
            <strong>PERHATIKAN: </strong>
            Setiap nomor hanya akan ada 1 pernyataan yang dapat dipilih di kolom{' '}
            <strong className={classes.textP}>Hijau</strong> dan 1 pernyataan di
            kolom <strong className={classes.textK}>Merah</strong>
          </Typography>
          <hr></hr>
          <form onSubmit={(e) => onClickSubmitTest(e)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {questionChunks[0].map((item, index) => {
                  return (
                    <Card className={classes.card} key={index}>
                      <CardContent>
                        <PRadio
                          required={true}
                          checked={selectedValue[`P${index}`] === item.a}
                          onChange={handleChange}
                          value={item.a}
                          name={`P${index}`}
                          inputProps={{'aria-label': 'A'}}
                        />
                        <KRadio
                          required={true}
                          checked={selectedValue[`K${index}`] === item.a}
                          onChange={handleChange}
                          value={item.a}
                          name={`K${index}`}
                          inputProps={{'aria-label': item.a}}
                        />
                        {item.a}
                        <br />
                        <PRadio
                          required={true}
                          checked={selectedValue[`P${index}`] === item.b}
                          onChange={handleChange}
                          value={item.b}
                          name={`P${index}`}
                          inputProps={{'aria-label': 'B'}}
                        />
                        <KRadio
                          required={true}
                          checked={selectedValue[`K${index}`] === item.b}
                          onChange={handleChange}
                          value={item.b}
                          name={`K${index}`}
                          inputProps={{'aria-label': item.b}}
                        />
                        {item.b}
                        <br />
                        <PRadio
                          required={true}
                          checked={selectedValue[`P${index}`] === item.c}
                          onChange={handleChange}
                          value={item.c}
                          name={`P${index}`}
                          inputProps={{'aria-label': 'C'}}
                        />
                        <KRadio
                          required={true}
                          checked={selectedValue[`K${index}`] === item.c}
                          onChange={handleChange}
                          value={item.c}
                          name={`K${index}`}
                          inputProps={{'aria-label': item.c}}
                        />
                        {item.c}
                        <br />
                        <PRadio
                          required={true}
                          checked={selectedValue[`P${index}`] === item.d}
                          onChange={handleChange}
                          value={item.d}
                          color='default'
                          name={`P${index}`}
                          inputProps={{'aria-label': 'D'}}
                        />
                        <KRadio
                          required={true}
                          checked={selectedValue[`K${index}`] === item.d}
                          onChange={handleChange}
                          value={item.d}
                          name={`K${index}`}
                          inputProps={{'aria-label': item.d}}
                        />
                        {item.d}
                        <br />
                      </CardContent>
                    </Card>
                  );
                })}
              </Grid>
              <Grid item xs={6}>
                {questionChunks[1].map((item, index) => {
                  index = questionChunks[1].length + index;
                  return (
                    <Card className={classes.card} key={index}>
                      <CardContent>
                        <PRadio
                          required={true}
                          checked={selectedValue[`P${index}`] === item.a}
                          onChange={handleChange}
                          value={item.a}
                          name={`P${index}`}
                          inputProps={{'aria-label': 'A'}}
                        />
                        <KRadio
                          required={true}
                          checked={selectedValue[`K${index}`] === item.a}
                          onChange={handleChange}
                          value={item.a}
                          name={`K${index}`}
                          inputProps={{'aria-label': item.a}}
                        />
                        {item.a}
                        <br />
                        <PRadio
                          required={true}
                          checked={selectedValue[`P${index}`] === item.b}
                          onChange={handleChange}
                          value={item.b}
                          name={`P${index}`}
                          inputProps={{'aria-label': 'B'}}
                        />
                        <KRadio
                          required={true}
                          checked={selectedValue[`K${index}`] === item.b}
                          onChange={handleChange}
                          value={item.b}
                          name={`K${index}`}
                          inputProps={{'aria-label': item.b}}
                        />
                        {item.b}
                        <br />
                        <PRadio
                          required={true}
                          checked={selectedValue[`P${index}`] === item.c}
                          onChange={handleChange}
                          value={item.c}
                          name={`P${index}`}
                          inputProps={{'aria-label': 'C'}}
                        />
                        <KRadio
                          required={true}
                          checked={selectedValue[`K${index}`] === item.c}
                          onChange={handleChange}
                          value={item.c}
                          name={`K${index}`}
                          inputProps={{'aria-label': item.c}}
                        />
                        {item.c}
                        <br />
                        <PRadio
                          required={true}
                          checked={selectedValue[`P${index}`] === item.d}
                          onChange={handleChange}
                          value={item.d}
                          color='default'
                          name={`P${index}`}
                          inputProps={{'aria-label': 'D'}}
                        />
                        <KRadio
                          required={true}
                          checked={selectedValue[`K${index}`] === item.d}
                          onChange={handleChange}
                          value={item.d}
                          name={`K${index}`}
                          inputProps={{'aria-label': item.d}}
                        />
                        {item.d}
                        <br />
                      </CardContent>
                    </Card>
                  );
                })}
              </Grid>
            </Grid>

            <CardActions>
              <Button variant='contained' color='primary' type='submit'>
                Kirim
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

CandidateLogin.propTypes = {
  candidateLogin: PropTypes.func.isRequired,
  getQuestions: PropTypes.func.isRequired,
  loginCheck: PropTypes.func.isRequired,
  postResult: PropTypes.func.isRequired,
  candidates: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  candidates: state.candidates,
  questions: state.questions,
});

export default connect(mapStateToProps, {
  candidateLogin,
  loginCheck,
  getQuestions,
  postResult,
})(CandidateLogin);
