import {
  GET_USERS,
  GET_USER,
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
} from '../actions/types';

const initialState = {
  user: {},
  users: [],
  loadingUser: true,
  error: {},
};

export default function users(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_USERS:
      return {
        ...state,
        user: {},
        users: payload,
        loadingUser: false,
      };
    case GET_USER:
      return {
        ...state,
        user: payload,
        users: [],
        loadingUser: false,
      };
    case ADD_USER:
      return {
        ...state,
        user: payload,
        users: [payload, ...state.users],
        loadingUser: false,
      };
    default:
      return state;
  }
}
