import axios from 'axios';
import {setAlert} from './alert';
import {GET_DOWNLOAD} from './types';

export const getDownloadLink = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/download/${payload}`
    );
    dispatch({
      type: GET_DOWNLOAD,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert(error.message, 'error'));
    console.log(error);
  }
};
